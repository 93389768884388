import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/content-page.js";
import { HardWraps } from "../components/layout/layout";
export const Head = () => <title>Impressum | KalkSpace</title>;
export const _frontmatter = {};
const layoutProps = {
  Head,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Impressum`}</h1>
    <HardWraps mdxType="HardWraps">
      <p>{`Anbieter:
KalkSpace e.V.
Olpener Str. 33
51103 Köln`}</p>
      <p>{`Kontakt:
Telefon: 0221-98653919
E-Mail: `}<a parentName="p" {...{
          "href": "mailto:hallo@kalk.space"
        }}>{`hallo@kalk.space`}</a>{`
Website: `}<a parentName="p" {...{
          "href": "https://kalk.space/"
        }}>{`https://kalk.space/`}</a></p>
      <p>{`Bei redaktionellen Inhalten:
Verantwortlich nach § 55 Abs.2 RStV
KalkSpace e.V.
Olpener Str. 33
51103 Köln`}</p>
    </HardWraps>
    <h2>{`Credits`}</h2>
    <p>{`Fotos teilweise von `}<a parentName="p" {...{
        "href": "danielgruenfeld.com"
      }}>{`Daniel Grünfeld`}</a>{`. Vielen Dank!`}</p>
    <p>{`Icons made by `}<a parentName="p" {...{
        "href": "https://www.flaticon.com/authors/freepik"
      }}>{`freepik`}</a>{` from `}<a parentName="p" {...{
        "href": "https://www.flaticon.com/"
      }}>{`www.flaticon.com`}</a>{` is licensed by `}<a parentName="p" {...{
        "href": "http://creativecommons.org/licenses/by/3.0/"
      }}>{`CC 3.0 BY`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      